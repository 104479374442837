import { breakpointsVuetifyV3 } from '@vueuse/core'

import { VIcon } from 'vuetify/components/VIcon'

// ❗ Logo SVG must be imported with ?raw suffix
import logo_name from '@images/svg/conntac_logo_name.svg?raw'
import logo_icon from '@images/svg/conntac_logo_icon.svg?raw'

import { defineThemeConfig } from '@core'
// import { RouteTransitions } from '@core/src/@core/enums'
import { Skins } from '@core/enums'
import { AppContentLayoutNav, ContentWidth, FooterType, NavbarType } from '@layouts/enums'

const parser = new DOMParser()
const logoDoc = parser.parseFromString(logo_name, 'image/svg+xml')
const logoElement = logoDoc.documentElement
const collapsedLogoDoc = parser.parseFromString(logo_icon, 'image/svg+xml')
const collapsedLogoElement = collapsedLogoDoc.documentElement

logoElement.style.height = '30px'
logoElement.style.width = '107px'

export const { themeConfig, layoutConfig } = defineThemeConfig({
  app: {
    title: '',
    logo: h('div', { innerHTML: logoElement.outerHTML, style: 'line-height:0; color: rgb(var(--v-global-theme-primary));' }, [
      h('svg', {
        style: {
          height: '30px'
        },
      })
    ]),
    collapsedLogo: h('div', { innerHTML: collapsedLogoElement.outerHTML, style: 'line-height: 0; color: rgb(var(--v-global-theme-primary)); margin-left: -6px !important;' }, [
      h('svg', {
        style: {
          height: '30px',
          marginLeft: '-6px !important'
        },
      })
    ]),
    contentWidth: ContentWidth.Boxed,
    contentLayoutNav: AppContentLayoutNav.Vertical,
    overlayNavFromBreakpoint: breakpointsVuetifyV3.lg - 1, // 16 for scrollbar. Docs: https://next.vuetifyjs.com/en/features/display-and-platform/
    i18n: { 
        enable: false, 
        defaultLocale: 'de', // Specify your default locale
        langConfig: [] // Provide an array of I18nLanguage objects
    },
    theme: 'light',
    skin: Skins.Default,
    iconRenderer: VIcon,
    
  },
  navbar: {
    type: NavbarType.Sticky,
    navbarBlur: true,
  },
  footer: { type: FooterType.Static },
  verticalNav: {
    isVerticalNavCollapsed: false,
    defaultNavItemIconProps: { icon: 'feather-circle', size: 10 },
    isVerticalNavSemiDark: false,
  },
  horizontalNav: {
    type: NavbarType.Static,
    transition: 'slide-y-reverse-transition',
    popoverOffset: 6,
  },

  /*
  // ℹ️  In below Icons section, you can specify icon for each component. Also you can use other props of v-icon component like `color` and `size` for each icon.
  // Such as: chevronDown: { icon: 'feather-chevron-down', color:'primary', size: '24' },
  */
  icons: {
    chevronDown: { icon: 'feather-chevron-down' },
    chevronRight: { icon: 'feather-chevron-right', size: 18 },
    close: { icon: 'feather-x' },
    verticalNavPinned: { icon: 'feather-disc', size: 18 },
    verticalNavUnPinned: { icon: 'feather-circle', size: 18 },
    sectionTitlePlaceholder: { icon: 'feather-more-horizontal', size: 18, color: '#595959' },
  },
})
