import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/store/auth-store/useAuthStore'
import { OpenAPI } from '@/api'
import { routes } from 'vue-router/auto-routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/pages/user-profile',
      redirect: () => ({ name: 'root' }),
    },
    {
      path: '/',
      redirect: () => ({ name: 'sessions' }),
    },
    ...setupLayouts(routes),
  ],
})

router.beforeEach(async to => {
  const authStore = useAuthStore()
  const { hasPermission, featuresPermitted } = authStore
  const loggedInUser = await authStore.isUserLoggedIn()

  if (!loggedInUser && !to.meta.DontRedirectToLogin) {
    if (authStore.newerFrontendVersionAvailable)
      window.location.href = '/login'
    else
      return { name: 'login' }
  }
  else if (loggedInUser && to.meta.requiresPermission) {
    if (localStorage.getItem('accessToken'))
      OpenAPI.TOKEN = JSON.parse(localStorage.getItem('accessToken') || '{}')

    // @ts-expect-error
    if (!(hasPermission(to.meta.requiresPermission) && featuresPermitted(to.meta.requiresFeature))) {
      if (authStore.newerFrontendVersionAvailable)
        window.location.href = '/'
      else
        return { name: 'root' }
    }
    else {
      if (authStore.newerFrontendVersionAvailable)
        window.location.href = to.fullPath
      else
        return
    }
  }
  else {
    OpenAPI.TOKEN = JSON.parse(localStorage.getItem('accessToken') || '{}')

    if (authStore.newerFrontendVersionAvailable)
      window.location.href = to.fullPath
  }
})

// Handle 404 after updates. Code taken from https://github.com/vitejs/vite/issues/11804#issuecomment-2393679584
router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    console.log(`Forcefully navigating to ${to.fullPath} since loading the dynamic module failed.`)
    window.location.href = to.fullPath
  }
})

export default router

