import { OpenAPI } from '@/api'
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar'
import 'vue3-perfect-scrollbar/style.css'

import gettext from '@/plugins/gettext'
import App from '@/App.vue'

import layoutsPlugin from '@/plugins/layouts'
import vuetify from '@/plugins/vuetify'
import '@fontsource/open-sans'
import '@fontsource/open-sans/300.css'
import '@fontsource/open-sans/400.css'
import '@fontsource/open-sans/500.css'
import '@fontsource/open-sans/600.css'
import '@fontsource/open-sans/700.css'

import router from '@/router'
import '@core/scss/template/index.scss'

import { VueMasonryPlugin } from 'vue-masonry'
import '@styles/styles.scss'

import iconify from '@/plugins/iconify'

import { pinia } from '@/plugins/pinia'
import { createApp } from 'vue'
import * as Sentry from '@sentry/vue'

declare global {
  interface Window {
    FIELD_SERVICE_API_URL: string
    SENTRY_DSN?: string
    SENTRY_ENVIRONMENT?: string
  }
}

OpenAPI.BASE = window.FIELD_SERVICE_API_URL

export async function initApp() {
  if(!process.env.VUE_APP_FIELD_SERVICE_API_URL)
    return

  const api_url = new URL(process.env.VUE_APP_FIELD_SERVICE_API_URL)

  api_url.pathname = "api/dashboard/config"
  try {
    console.log('api_url', api_url.toString())

    const response = await fetch(api_url.toString())
    if (!response.ok) {
      console.log("Development backend not callable, using staging.")
    } else {
      OpenAPI.BASE = process.env.VUE_APP_FIELD_SERVICE_API_URL
    }
  } catch (error) {
    console.error("Error fetching config:", error)
  }
}

if (process.env && ['development', 'test'].includes(process.env.VUE_APP_ENV as string) && process.env.VUE_APP_FIELD_SERVICE_API_URL) {
  initApp().catch(console.error)
}

// Create vue app
const app = createApp(App)

// Setup sentry
const release = import.meta.env.VITE_SENTRY_RELEASE
const dsn = window.SENTRY_DSN
const environment = window.SENTRY_ENVIRONMENT

if (dsn) {
  Sentry.init({
    app,
    dsn,
    release,
    environment,
    integrations: [
      Sentry.httpClientIntegration(),
      Sentry.captureConsoleIntegration(),
      Sentry.replayIntegration(),
      Sentry.browserTracingIntegration(),
    ],
    tracesSampleRate: 1.0,

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 1.0,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  })
}

// Use plugins
app.use(gettext)
app.use(iconify)
app.use(VueMasonryPlugin)
app.use(vuetify)
app.use(pinia)
app.use(router)
app.use(layoutsPlugin)
app.use(PerfectScrollbarPlugin)

// Mount vue app
app.mount('#app')
