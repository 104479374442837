<script lang="ts" setup>
import { layoutConfig } from '@layouts'
import { can } from '@layouts/plugins/casl'
import { useLayoutConfigStore } from '@layouts/stores/config'
import type { NavLink } from '@layouts/types'
import { getComputedNavLinkToProp, getDynamicI18nProps, isNavLinkActive } from '@layouts/utils'

const props = defineProps<{
  item: NavLink
}>()

const badgeContent = ref(0)
const showBadge = ref(false)
const url = ref<NavLink>(props.item)

const changeBadgeContent = (content: number) => {
  if (content === 0)
    showBadge.value = false
  else
    showBadge.value = true
  badgeContent.value = content
}

const changeQuery = (status: string | string[]) => {
  if(url.value.to) {
    if (typeof url.value.to === 'string') {
      // If `to` is a string, convert it to an object
      url.value.to = { path: url.value.to, query: { "status": status } }
    } else if (typeof url.value === 'object') {
      // If `to` is an object, merge the new query parameter
      url.value.to = {
        ...url.value.to,
        query: {
          "status": status,
        },
      }
    }
  }
}

const changeQueryContent = (badgeNumber: number) => {
  if (badgeNumber > 0) {
    changeQuery(["pending", "approved"] as string[])
  } else {
    changeQuery("approved")
  }
}

if (props.item.badgeStore) {
  changeBadgeContent(props.item.badgeStore.getBadgeNumber)

  watch(props.item.badgeStore, newVal => {
    changeBadgeContent(newVal.getBadgeNumber)
  })
}
const configStore = useLayoutConfigStore()
const hideTitleAndBadge = configStore.isVerticalNavMini()
</script>

<template>
  <li
    v-if="can(item.action, item.subject)"
    class="nav-link"
    :class="{ disabled: url.disable }"
  >
    <Component
      :is="item.to ? 'RouterLink' : 'a'"
      v-if="item.to"
      v-bind="getComputedNavLinkToProp(item)"
      :class="{ 'router-link-active router-link-exact-active': isNavLinkActive(item, $router) }"
    >
      <Component
        :is="layoutConfig.app.iconRenderer || 'div'"
        v-bind="item.icon || layoutConfig.verticalNav.defaultNavItemIconProps"
        class="nav-item-icon"
      />
      <TransitionGroup name="transition-slide-x">
        <!-- 👉 Title -->
        <Component
          :is="layoutConfig.app.i18n.enable ? 'i18n-t' : 'span'"
          v-show="!hideTitleAndBadge"
          key="title"
          class="nav-item-title"
          v-bind="getDynamicI18nProps(item.title, 'span')"
        >
          {{ url.title }}
        </Component>

        <!-- 👉 Badge -->
        <Component
          :is="layoutConfig.app.i18n.enable ? 'i18n-t' : 'span'"
          v-if="item.badgeContent && showBadge"
          v-show="!hideTitleAndBadge"
          key="badge"
          class="nav-item-badge"
          :class="item.badgeClass"
          v-bind="getDynamicI18nProps(badgeContent.toString(), 'span')"
        >
          {{ badgeContent }}
        </Component>
      </TransitionGroup>
    </Component>
  </li>
</template>

<style lang="scss">
.layout-vertical-nav {
  .nav-link a {
    display: flex;
    align-items: center;
  }

  .nav-item-badge {
    background-color: rgb(var(--v-theme-primary));
    color: rgb(var(--v-theme-on-primary));
  }

  .router-link-exact-active {
    .nav-item-badge {
      background-color: rgb(var(--v-theme-on-primary));
      color: rgb(var(--v-theme-primary));
    }
  }
}
</style>
