import { createGettext } from 'vue3-gettext'
import translations from './language/translations.json'

const gettext = createGettext({
  availableLanguages: {
    en: 'English',
    de: 'German',
  },
  mutedLanguages: ['en'],
  defaultLanguage: 'en',
  translations,
})

// Change the user language based on his locale if it is supported
if (navigator.language) {
  let userLocale = navigator.language
  if (navigator.language.includes('-'))
    userLocale = navigator.language.split('-')[0]
  if (userLocale in gettext.available)
    gettext.current = userLocale
}

export default gettext
